import React from 'react'

function DateClick() {
    return (
        <div>
            <div className="item" >
                <div className="wrap-best wrap-best-v3 text-uppercase">
                    <p>
                        <span>Sat</span><br />
                        <span style={{ fontWeight: 'bold' }}>26</span><br />
                        <span>Apr</span>
                    </p>

                </div>
            </div>
        </div>
    )
}

export default DateClick